var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "app-form",
    { attrs: { id: "attachment-types-search-form", submitAction: _vm.search } },
    [
      _c(
        "div",
        { staticClass: "attachment-types" },
        [
          _c("app-dropdownlist", {
            attrs: {
              id: "attachmentTypeId",
              placeholder: _vm.$t("attachmentTypes.attachmentType"),
              dataSource: _vm.attachmentTypes,
              fields: { text: "name", value: "id" },
              cssClass: "e-field"
            },
            model: {
              value: _vm.attachmentTypeId,
              callback: function($$v) {
                _vm.attachmentTypeId = $$v
              },
              expression: "attachmentTypeId"
            }
          })
        ],
        1
      ),
      _c(
        "app-button",
        { attrs: { cssClass: "e-primary attachment-types-search" } },
        [_vm._v(" " + _vm._s(_vm.$t("shared.shortSearch")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }